// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archives-tsx": () => import("./../../../src/pages/archives.tsx" /* webpackChunkName: "component---src-pages-archives-tsx" */),
  "component---src-pages-diary-tsx": () => import("./../../../src/pages/diary.tsx" /* webpackChunkName: "component---src-pages-diary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-mini-4-wd-tsx": () => import("./../../../src/pages/mini4wd.tsx" /* webpackChunkName: "component---src-pages-mini-4-wd-tsx" */),
  "component---src-pages-spec-tsx": () => import("./../../../src/pages/spec.tsx" /* webpackChunkName: "component---src-pages-spec-tsx" */),
  "component---src-pages-superthomas-tsx": () => import("./../../../src/pages/superthomas.tsx" /* webpackChunkName: "component---src-pages-superthomas-tsx" */),
  "component---src-pages-touring-tsx": () => import("./../../../src/pages/touring.tsx" /* webpackChunkName: "component---src-pages-touring-tsx" */)
}

